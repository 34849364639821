import { FC } from "react";
import classNames from "classnames";
import { toNumber } from "lodash";

import { FormDataSplitType, PackageType } from "@lib/data/schemas/packages";
import { getFormatPeriod } from "@lib/packages";
import { displayItemAmountString } from "@lib/products";
import { getOrdinal } from "@lib/utils";

import ClockIcon from "@components/Icons/ClockIcon";
import NewPackageIcon from "@components/Icons/NewPackageIcon";
import PaymentOptionPreviewItem from "@components/Package/PaymentOptionPreviewItem";

import { getServiceFeeAmount, getTotalWithServiceFee } from "./Payments/utils";

interface PackagePaymentOptionPreviewProps {
  className?: string;
  total: number;
  currency: string;
  splits?: FormDataSplitType[];
  serviceFee?: PackageType["serviceFee"];
}

const PackagePaymentOptionPreview: FC<PackagePaymentOptionPreviewProps> = ({
  className,
  total,
  currency,
  splits,
  serviceFee,
}) => {
  const format = (total: number) => displayItemAmountString(total, currency);

  const totalWithoutFees = format(toNumber(total));
  const totalFeeAmount = format(getServiceFeeAmount(total, serviceFee));
  const totalWithFees = format(
    getTotalWithServiceFee(toNumber(total), serviceFee)
  );

  const renderLine = (
    title,
    value,
    className?: string,
    valueDescription?: string
  ) => (
    <div
      className={classNames(
        "flex justify-between items-center w-full font-medium",
        className
      )}
    >
      <h3 className="text-foreground">{title}</h3>
      <div className="text-foreground flex flex-col items-end">
        {value}
        {valueDescription && (
          <span className="text-xs text-foreground/50">{valueDescription}</span>
        )}
      </div>
    </div>
  );
  return (
    <div className={classNames("bg-foreground/7 rounded-lg p-4", className)}>
      {splits?.length ? (
        <div className="border-b mb-4 pb-4">
          {splits.map((split, index) => {
            const isDueAtBooking = split?.dueOn == 0 && index < 1;
            const title = isDueAtBooking
              ? "Deposit due at booking"
              : getFormatPeriod(split.dueOn, split.period);
            const subtitle = isDueAtBooking
              ? "Charged immediately"
              : "Scheduled automatically";
            const icon = isDueAtBooking ? (
              <NewPackageIcon className="text-accent h-6 w-6" />
            ) : (
              <ClockIcon className="text-foreground/50 h-4 w-4" />
            );

            const serviceFeeAmount = getServiceFeeAmount(
              split.total,
              serviceFee
            );
            return (
              <PaymentOptionPreviewItem
                key={`split_preview_${index}`}
                title={
                  isDueAtBooking ? title : `${getOrdinal(index + 1)} ${title}`
                }
                subtitle={subtitle}
                icon={icon}
                splitTotal={
                  displayItemAmountString(split.total, currency) as string
                }
                isDueAtBooking={isDueAtBooking}
                serviceFeeAmount={
                  serviceFeeAmount
                    ? displayItemAmountString(serviceFeeAmount, currency)
                    : undefined
                }
              />
            );
          })}
        </div>
      ) : (
        serviceFee && (
          <div className="pb-4 border-b border-b-grey-800 mb-4 flex flex-col gap-2">
            {renderLine("Total", totalWithoutFees, "text-sm", "Taxes included")}
            {renderLine("Service fee", totalFeeAmount, "text-sm")}
          </div>
        )
      )}
      <div>
        {renderLine(
          "Total payment",
          totalWithFees,
          undefined,
          !serviceFee ? "Taxes included" : undefined
        )}
      </div>
    </div>
  );
};

export default PackagePaymentOptionPreview;
