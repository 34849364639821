import { PackagePaymentSplit } from "@practice/sdk";

import { CouponType } from "@lib/data/schemas/coupon";
import { PackageType } from "@lib/data/schemas/packages";
import { TaxTypeType } from "@lib/data/schemas/taxType";

export const getTotalWithServiceFee = (
  total: number,
  serviceFee: PackageType["serviceFee"]
) => {
  if (!serviceFee) return total;
  if (serviceFee.type === "flat") return total + serviceFee.amount;
  return total + (serviceFee.amount / 100) * total;
};

export const getServiceFeeAmount = (
  total: number,
  serviceFee?: PackageType["serviceFee"]
) => {
  if (!serviceFee) return 0;
  if (serviceFee.type === "flat") return serviceFee.amount;
  return (serviceFee.amount / 100) * total;
};

const round = (number: number) => Math.round(number * 100) / 100;

export type GetPaymentBreakdownProps = {
  split: PackagePaymentSplit;
  serviceFee: PackageType["serviceFee"];
  taxType: TaxTypeType;
  coupon?: CouponType;
};

type PaymentBreakdown = {
  packagePrice: number;
  serviceFeeAmount: number;
  subtotal: number;
  discountAmount: number;
  totalExcludingTax: number;
  taxAmount: number;
  total: number;
};

export const getOneTimePaymentBreakdown = ({
  split,
  serviceFee,
  taxType,
  coupon,
}: GetPaymentBreakdownProps): PaymentBreakdown => {
  const isInclusiveTax = taxType.inclusive;
  const { itemAmount, total: splitTotal } = split;

  const packagePrice = (isInclusiveTax ? splitTotal : itemAmount) ?? 0;
  const serviceFeeAmount = getServiceFeeAmount(packagePrice, serviceFee);
  const subtotal = packagePrice + serviceFeeAmount;

  const discountAmount = coupon
    ? coupon.unit === "%"
      ? (subtotal * coupon.value) / 100
      : coupon.value
    : 0;

  const subtotalWithDiscount = Math.max(subtotal - discountAmount, 0);

  const taxAmount = round(subtotalWithDiscount * (taxType.percentage / 100));
  const totalExcludingTax =
    subtotalWithDiscount - (isInclusiveTax ? taxAmount : 0);

  const total = totalExcludingTax + taxAmount;

  return {
    packagePrice,
    serviceFeeAmount,
    subtotal,
    discountAmount,
    totalExcludingTax,
    taxAmount,
    total,
  };
};

export type SplitPaymentBreakdown = {
  paymentOption: PackageType["paymentOptions"][0];
  serviceFee: PackageType["serviceFee"];
  taxType: TaxTypeType;
};

export const getSplitPaymentBreakdown = ({
  paymentOption,
  serviceFee,
  taxType,
}: SplitPaymentBreakdown) => {
  const { splits } = paymentOption;

  const splitBreakdowns = splits.map((split) =>
    getOneTimePaymentBreakdown({
      split: split as PackagePaymentSplit,
      serviceFee,
      taxType,
    })
  );

  const totalToPay = splitBreakdowns.reduce((acc, breakdown) => {
    acc += breakdown.total;
    return acc;
  }, 0);

  return {
    splitBreakdowns,
    totalToPay,
  };
};
