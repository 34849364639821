import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useFieldArray, useFormContext } from "react-hook-form";
import axios from "axios";
import classNames from "classnames";

import { useCurrencies } from "@hooks/data/currencies";
import { useTaxTypes } from "@hooks/data/taxTypes";
import {
  FormDataOptionType,
  PackageFormDataType,
} from "@lib/data/schemas/packages";
import { SchedulerType } from "@lib/data/schemas/scheduler";

import { Button } from "@components/Button";
import Label from "@components/Form/Label";
import SectionForm from "@components/Form/SectionForm";
import SelectForm from "@components/Form/SelectForm";
import SeparatorForm from "@components/Form/SeparatorForm";
import TextFieldForm from "@components/Form/TextFieldForm";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import TrashIcon from "@components/Icons/TrashIcon";
import ContentListLayout from "@components/Layout/ContentListLayout";
import PackagePaymentOptionPreview from "@components/Package/PackagePaymentOptionPreview";

import { DepositRuleForm } from "./DepositRuleForm";
import PackageSchedulerItem from "./PackageSchedulerFormItem";
import UsageInvoicingForm from "./UsageInvoicingForm";
import { sortedTaxTypeOptions } from "./utils";

export const ScheduledPaymentInfo: FC = () => (
  <div className="bg-green-900 text-green-100 py-3 px-4 flex items-center gap-4 text-sm rounded-lg mt-2">
    <CreditCardIcon className="flex-none" />
    <p>
      Scheduled payments will be charged automatically to the payment method
      used for booking this package.
    </p>
  </div>
);

const PackagePaymentOptionForm: FC<{
  flexibleContentType?: PackageFormDataType["contentType"];
  isUsagePackage?: boolean;
  schedulers: SchedulerType[];
  usageInvoicing: PackageFormDataType["usageInvoicing"];
  depositRule: PackageFormDataType["depositRule"];
  serviceFee: PackageFormDataType["serviceFee"];
}> = ({
  flexibleContentType,
  isUsagePackage,
  schedulers,
  usageInvoicing,
  depositRule,
  serviceFee,
}) => {
  const { taxTypes } = useTaxTypes();
  const { currencies } = useCurrencies();
  const formMethods = useFormContext<FormDataOptionType>();
  const {
    register,
    control,
    setValue,
    formState: { errors },
    watch,
    clearErrors,
    setError,
  } = formMethods;
  const { remove, append } = useFieldArray({
    control,
    name: "splits",
  });

  const splits = watch("splits");

  const totalSplits = splits?.length || 0;

  const currencyOptions = useMemo(
    () => currencies.map(({ code }) => ({ value: code, label: code })),
    [currencies]
  );

  const taxTypeOptions = useMemo(() => {
    return taxTypes ? sortedTaxTypeOptions(taxTypes) : [];
  }, [taxTypes]);

  const type = watch("type");
  const amount = watch("amount");
  const taxTypeId = watch("taxTypeId");
  const currency = watch("currency");

  const billingPlansOptions = [
    {
      value: "one-time",
      label: "One time payment",
    },
    {
      value: "split-payments",
      label: "Split payments",
    },
  ];

  const periodOptions = [
    {
      value: "months",
      label: "Months",
    },
    {
      value: "weeks",
      label: "Weeks",
    },
  ];

  const isFirstTypeUpdate = useRef(true);
  useEffect(() => {
    if (!type) {
      return;
    }

    if (isFirstTypeUpdate.current) {
      isFirstTypeUpdate.current = false;
      return;
    }

    remove();
    if (type === "one-time") {
      append({
        unit: "%",
        amount: 100,
        dueOn: 0,
        period: "months",
      });
    }

    if (type === "split-payments") {
      append([
        {
          unit: "%",
          amount: 50,
          dueOn: 0,
          period: "months",
        },
        {
          unit: "%",
          dueOn: 1,
          period: "months",
        },
      ]);
    }
  }, [type]);

  const [calculatedTotal, setCalculatedTotal] = useState<number>();
  const splitsWithTotals = watch("splits");

  const minimumSplitAmountError = splitsWithTotals?.some(
    (s) => Number(s.total) < 1
  );

  useEffect(() => {
    if (minimumSplitAmountError && type === "split-payments")
      setError("type", {
        message: "Each payment must have a value of at least 1",
      });
    else clearErrors("type");
  }, [minimumSplitAmountError, type]);

  const calculateAndSetSplits = async () => {
    const response = await axios.post("/api/v1/calculate-package-payment", {
      amount,
      currency,
      taxTypeId,
      splits: watch("splits"),
    });
    setValue("splits", response?.data?.splits || []);
    setCalculatedTotal(response?.data?.total || 0);
  };

  useEffect(() => {
    calculateAndSetSplits();
  }, [JSON.stringify(splits), amount, type, taxTypeId]);

  const multiplier = flexibleContentType === "sessions" ? "Session" : "Hour";

  const label = isUsagePackage
    ? "Due at booking"
    : `Package price${flexibleContentType ? ` (per ${multiplier})` : ""}`;

  const renderPrice = (
    <TextFieldForm
      isCurrency
      containerClassName="mt-6 flex-col"
      label={label}
      placeholder="Enter total price"
      name="amount"
      register={register}
      errors={errors}
      required
      errorMessage="Package price is required"
      otherInputProps={{ min: 1 }}
      isCurrencyErrorMessage="Price should be a currency value of at least 1"
      helperChildClassName="px-[15px] text-foreground/500"
      helper="$"
      helperClassName="bg-foreground/7 rounded-l-lg"
      helperPrefix
      type="number"
    />
  );

  const renderTaxType = (
    <SelectForm
      containerClassName="mt-6"
      name="taxTypeId"
      control={control}
      options={taxTypeOptions}
      errors={errors}
      label="Tax type"
      placeholder="Tax type"
      required
      requiredErrorMessage="Tax type is required"
      indicatorSeparator={false}
      menuPosition="fixed"
    />
  );

  const renderCurrency = (
    <SelectForm
      containerClassName="mt-6"
      name="currency"
      control={control}
      options={currencyOptions}
      errors={errors}
      label="Currency"
      placeholder="Currency"
      required
      requiredErrorMessage="Currency is required"
      indicatorSeparator={false}
      menuPosition="fixed"
    />
  );

  return (
    <form className="contents" id="packagePaymentOptionsForm">
      <ContentListLayout containerClassName="px-0 md:px-0">
        {isUsagePackage && (
          <>
            <SectionForm title="Payment details">
              <div className="w-full mx-auto">
                {renderTaxType}
                {renderCurrency}
              </div>
            </SectionForm>
            <SeparatorForm />
          </>
        )}
        <SectionForm
          title="Pricing"
          description="Set a deposit amount due at booking and then cost billed to the client per session type."
        >
          <div className="w-full mx-auto">
            {renderPrice}
            {isUsagePackage ? (
              <>
                <Label className="mb-1">Rate per session</Label>
                {schedulers?.map((scheduler, index) => {
                  if (scheduler.groupScheduler?.type === "curriculum")
                    return null;
                  return (
                    <PackageSchedulerItem
                      index={index}
                      key={scheduler.id}
                      scheduler={scheduler}
                      showIcon
                      withPriceField
                      withQuantityField={false}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {renderTaxType}
                {renderCurrency}
              </>
            )}
          </div>
        </SectionForm>
        <SeparatorForm />
        <div className={classNames(isUsagePackage && "hidden")}>
          <SectionForm title="Billing">
            <div className="w-full mx-auto">
              <SelectForm
                name="type"
                control={control}
                options={billingPlansOptions}
                errors={errors}
                label="Billing plan"
                placeholder="Billing plan"
                required
                requiredErrorMessage="Billing plan is required"
                indicatorSeparator={false}
                menuPosition="fixed"
                disabled={!amount}
              />

              <div
                className={classNames({ hidden: !type || type === "one-time" })}
              >
                {splits.map((field, index) => {
                  if (index === 0) {
                    return (
                      <>
                        <TextFieldForm
                          type="number"
                          containerClassName="mt-6"
                          label="Due at booking"
                          placeholder="e.g. % due at booking"
                          name="splits.0.amount"
                          helperChildClassName="px-[15px] text-foreground/500"
                          helper="%"
                          helperClassName="bg-foreground/7"
                          register={register}
                          errors={errors}
                          required
                          errorMessage="% due at booking is required"
                          otherInputProps={{
                            defaultValue: field.amount,
                            min: 1,
                          }}
                          customValidation={(value: number) => {
                            if (type === "one-time") {
                              return undefined;
                            }
                            return value >= 100
                              ? "You can also select one time payment, if you want to charge 100% at the time of booking"
                              : value < 1
                              ? "Percentage due at booking must be greater than 0."
                              : totalSplits < 2
                              ? "Split payments must include 100% of the price. You must add at least one split payment or switch to One-time payment."
                              : undefined;
                          }}
                        />
                        <input
                          type="hidden"
                          defaultValue={field.unit}
                          {...register("splits.0.unit")}
                        />
                        <input
                          type="hidden"
                          defaultValue={field.dueOn}
                          {...register("splits.0.dueOn")}
                        />
                        <input
                          type="hidden"
                          defaultValue={field.period}
                          {...register("splits.0.period")}
                        />
                        {totalSplits > 1 && (
                          <label className="flex items-center text-sm leading-5 text-foreground/500 w-full mt-6">
                            Next payment due after
                          </label>
                        )}
                      </>
                    );
                  }

                  return (
                    index >= 0 && (
                      <div key={field.id} className="flex items-center gap-2">
                        <TextFieldForm
                          type="number"
                          placeholder="0"
                          name={`splits.${index}.dueOn`}
                          register={register}
                          errors={errors}
                          required
                          isNumber
                          customValidation={(value: number) =>
                            value < 0 || value > Number.MAX_VALUE
                              ? "Value must be a positive number"
                              : undefined
                          }
                          errorMessage="Value is required"
                          otherInputProps={{
                            defaultValue: field.dueOn,
                          }}
                          rightComponent={
                            <SelectForm
                              name={`splits.${index}.period`}
                              containerClassName="w-52"
                              control={control}
                              options={periodOptions}
                              errors={errors}
                              placeholder="Period"
                              isRightComponent
                              defaultValue={field.period}
                              selectProps={{
                                isClearable: false,
                                isSearchable: false,
                              }}
                              indicatorSeparator={false}
                              menuPosition="fixed"
                            />
                          }
                        />
                        <div
                          onClick={() => remove(index)}
                          className="cursor-pointer"
                        >
                          <TrashIcon />
                        </div>
                        <input
                          type="hidden"
                          defaultValue={field.unit}
                          {...register(`splits.${index}.unit`)}
                        />
                      </div>
                    )
                  );
                })}
                {type === "split-payments" && (
                  <Button
                    className="mt-2"
                    onClick={(e) => {
                      e.preventDefault();
                      append({
                        unit: "%",
                        period: "months",
                        dueOn: totalSplits,
                      });
                    }}
                  >
                    Add another split
                  </Button>
                )}
              </div>
            </div>
          </SectionForm>
        </div>
        {isUsagePackage && (
          <>
            <SectionForm title="Invoicing">
              <FormProvider {...formMethods}>
                <UsageInvoicingForm usageInvoicing={usageInvoicing} />
              </FormProvider>
            </SectionForm>
            <SectionForm
              title="Deposit rules"
              description="Your deposit can apply to the start of the engagement, or the end of engagement"
            >
              <FormProvider {...formMethods}>
                <DepositRuleForm depositRule={depositRule} />
              </FormProvider>
            </SectionForm>
            <SeparatorForm />
          </>
        )}
        {amount && !isUsagePackage && (
          <>
            <SeparatorForm />
            <SectionForm
              title="Preview"
              description="What your clients will see when booking this package."
            >
              <div>
                <PackagePaymentOptionPreview
                  total={calculatedTotal || Number(amount)}
                  currency={currency}
                  splits={
                    type === "split-payments" ? splitsWithTotals : undefined
                  }
                  serviceFee={serviceFee}
                />
              </div>
              {type === "split-payments" && <ScheduledPaymentInfo />}
            </SectionForm>
          </>
        )}
      </ContentListLayout>
    </form>
  );
};

export default PackagePaymentOptionForm;
