import { FC } from "react";
import classNames from "classnames";

interface PaymentOptionPreviewItemProps {
  title: string;
  subtitle: string;
  icon: JSX.Element;
  splitTotal: string;
  isDueAtBooking: boolean;
  serviceFeeAmount?: string;
}

const PaymentOptionPreviewItem: FC<PaymentOptionPreviewItemProps> = ({
  title,
  subtitle,
  icon,
  splitTotal,
  isDueAtBooking,
  serviceFeeAmount,
}) => {
  return (
    <>
      {!isDueAtBooking && (
        <div
          className={classNames("border-foreground/20 border-l-2 h-4 ml-2.5")}
        ></div>
      )}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div
            className={classNames(
              isDueAtBooking
                ? "bg-accent/10"
                : classNames("p-1 bg-foreground/7"),
              "rounded-full w-min flex-none"
            )}
          >
            {icon}
          </div>
          <div>
            <h3
              className={classNames(
                isDueAtBooking
                  ? "text-accent"
                  : classNames("text-foreground/50"),
                "text-sm"
              )}
            >
              {title}
            </h3>
            <p className="text-xs text-foreground/50">{subtitle}</p>
          </div>
        </div>

        {splitTotal && (
          <div className="text-right">
            <div className={classNames("font-medium text-sm text-foreground")}>
              {splitTotal}
              {serviceFeeAmount && (
                <span className="pl-1">+ {serviceFeeAmount} service fee</span>
              )}
            </div>
            <div className="text-foreground/50 text-xs">Taxes included</div>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentOptionPreviewItem;
